import React, { cloneElement } from 'react';
import { GraphUp, Gear, ShieldCheck, BarChartLine } from '@styled-icons/bootstrap'
import { MoneyWithdraw } from '@styled-icons/boxicons-regular';
import { ArrowTrendingSettings } from '@styled-icons/fluentui-system-regular';
import {ArrowTrendingWrench} from '@styled-icons/fluentui-system-filled'

const features = [
   {
      title: 'Quick Time to Market',
      description: 'Launch integrations 10-100x faster with minimal engineering effort, reducing go-to-market delays.​',
      icon: <GraphUp />, // Replace with actual icon or icon component if you have one
   },
   {
      title: 'Customizable Configurations',
      description: 'Tailor integrations to meet customer-specific needs with Unizo’s flexible platform.​',
      icon: <ArrowTrendingWrench />, // Replace with actual icon
   },
   {
      title: 'Powerful Support Tools',
      description: 'Equip your team with tools that make debugging, customer support, and monitoring integrations a breeze.',
      icon: <ArrowTrendingSettings />, // Replace with actual icon
   },
   {
      title: 'Scalable Architecture',
      description: `From startups to enterprise-grade use cases, Unizo grows with your business, ensuring your integration ecosystem remains robust and reliable.​`,
      icon: <BarChartLine />, // Replace with actual icon
      className: 'col-start-2 col-span-1'
   }
];

export const HilightPoints = () => {
   return (
      <div className='services-talking-points'>
         <div className=" py-16 px-6 md:px-20 lg:px-32 layout">
            <h2 className="container-title ">
            Why Developers Love Unizo?
            </h2>
            <div className="services-talking-points-list gap-5 justify-items-center" >
               {features.map(({ className, ...feature }: Record<string, any>, index) => (
                  <div key={index} className={`bg-white p-6 text-center  ` + className} style={{ maxWidth: '18rem' }}>
                     <div className='flex flex-row justify-center'>
                        <div className='featuredIcon mb-4 flex-shrink-0'>
                           {feature.icon ? cloneElement(feature.icon, {
                              className: "h-6 w-6",
                           }) : null}
                        </div>
                     </div>
                     <h3 className="text-xl text-gray-800 mb-3">
                        {feature.title}
                     </h3>
                     <p className="text-gray-600 font-normal">{feature.description}</p>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};
