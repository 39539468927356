import { Link } from 'gatsby';
import React, { HTMLProps, cloneElement } from 'react';
import { DiscordAlt, Twitter, Linkedin } from '@styled-icons/boxicons-logos';
import { cva } from 'class-variance-authority';
import { Label } from '@styled-icons/boxicons-regular';

const githubUsernames = [
  'sudhanvag'
];
const innerCls = cva(
  "mx-auto flex w-full flex-col items-center justify-center primary-gradient px-4 py-16 text-white"
)

type CommunitySectionProps = HTMLProps<HTMLDivElement & { title: any }> & {
  description?: string
  rootClassName?: string
  actions?: {
    to?: string,
    label?: string,
    target?: string,
    icon?: React.ReactElement,
    iconClassName?: string
  }[]
  showEmoji?: boolean
}

const defaultTitle = (
  <>

  </>
)

const iconClassName = cva(
  'h-6 w-6'
)

export default function CommunitySection({ rootClassName, className = '', ...props }: any) {

  const {
    title = defaultTitle,
    description = ` `,
    actions = [],
    showEmoji = false,
  } = props;

  return (
    <section
      className={`${"no-underline-links"} ${rootClassName}`}
    >
      <div className={innerCls({ className })} {...props}>

        {<div className='flex flex-col gap-3 items-center justify-center'>
          <h2 className="h2 text-black">
            {title}
          </h2>
          <p className="max-w-4xl mb-10 text-zinc-400 text-center">
            {description}
          </p>
        </div>}

        {showEmoji && (
          <div className="mx-auto mb-16 flex flex-wrap -space-x-1.5">
            {githubUsernames.map((username) => (
              <img
                key={username}
                src={`https://github.com/${username}.png?size=60`}
                alt={`User ${username}`}
                loading="lazy"
                className="h-6 w-6 rounded-full border-2 border-solid border-white transition hover:-translate-y-2 hover:scale-150 lg:h-12 lg:w-12"
              />
            ))}
          </div>
        )}

        {actions.length ? (
          <div className="flex w-full flex-col items-center justify-center gap-2 text-sm font-semibold lg:flex-row lg:gap-8">
            {actions.map((
              {
                target = 'self',
                to = '/lets-chat',
                icon = null,
                label,
                iconClassName: iconClassNameProp,
              }) => (
              <Link
                className="flex w-full items-center no-underline justify-center gap-2 rounded-sm border border-solid btn btn-lg lg:w-auto"
                to={to}
                target={target}
              >
                {icon && (
                  cloneElement(icon, { className: iconClassName({ className: iconClassNameProp }) })
                )}
                {label}
                &rarr;
              </Link>
            ))}
          </div>
        ) : null}

      </div>
    </section>
  );
}
