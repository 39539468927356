import React, { HTMLProps } from 'react';
import { cva } from 'class-variance-authority';

type ItemTypes = {
   name: string
   value: string
   description?: string
   [key: string]: any
}

type Props = {

   items: Array<ItemTypes>
   selected?: string

   onSelect?: (selected: string) => void

} & HTMLProps<HTMLDivElement>;

const itemVariant = cva(
   'flex items-center p-2 cursor-pointer user-select-none text-base font-normal text-gray-900 rounded-sm ',
   {
      variants: {
         IsSelected: {
            true: 'bg-gray-100',
            false: ''
         }
      }
   }
)

export default (props: Props) => {

   const {
      selected,
      items = [],
      onSelect: onSelectProp,
      ...rest
   } = props;

   const onSelect = (newSelected: string) => {
      if (typeof onSelectProp === 'function') {
         onSelectProp(newSelected)
      }
   }

   return (
      <div {...rest}>
         <div className="space-y-2 sticky top-[5rem] ">
            <div className='mb-5'>
               <span className="ml-3 text-2xl font-bold">Categories</span>
            </div>
            {items.map((item: any, index: number) => {
               const IsSelected = item.value === selected;
               return (
                  <div
                     key={index}
                     onClick={() => {
                        void onSelect(item.value)
                     }}
                     className={itemVariant({ IsSelected })}
                  >
                     {/* <div className='h-2 w-2  rounded-full' style={{ background: item?.color }} /> */}
                     <div className='flex flex-col gap-1'>
                        <span className="ml-3 text-md font-semibold">{item?.name}</span>
                     </div>
                  </div>
               )
            })}
         </div>
      </div>
   )
}
