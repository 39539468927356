import React, { CSSProperties } from 'react';
import { ProSectionContainer } from '../../../src/components/common/ProSectionContainer';

const TITLE = 'Program Benefits at a Glance';
const DESCRIPTIONS = ' '

const CARD_STYLE: CSSProperties = {
   background: 'radial-gradient(circle at 50% 0px, rgb(33, 43, 61), rgb(28, 34, 49))'
}

const CARD_ITEM_S: { title: string, description: string }[] = [
      {
        "title": "Free Tier",
        "description": "Get started with Unizo without any financial commitment."
      },
      {
        "title": "Tailored Support",
        "description": "Dedicated guidance from our integration experts."
      },
      {
        "title": "Product Collaboration",
        "description": "Influence the direction of Unizo’s platform to better meet your needs."
      },
      {
        "title": "Discounted Pricing",
        "description": "Access premium capabilities at a fraction of the cost."
      },
      {
        "title": "Early Feature Access",
        "description": "Stay ahead of the competition with early access to innovations."
      },
      {
        "title": "Custom Integrations",
        "description": "Collaborate with Unizo to develop integrations that fit your needs."
      },
];

const SecurityCards = () => {
   return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-6 mt-2">

         {CARD_ITEM_S.map((item, index) => {
            return (
               <div style={CARD_STYLE} className="p-6 rounded-lg shadow-lg text-start bg-gradient-to-r from-blue-gray-800 via-blue-gray-800 to-blue-gray-800" key={index} >
                  <h3 className="text-xl font-bold">{item.title}</h3>
                  <p className='!mb-0 mt-2 font-normal leading-6 text-sm text-white break-words text-justify max-w-full'>
                     {item.description}
                  </p>
               </div>
            )
         })}

      </div>
   );
};

export const Pbg = () => {
   return (
      <ProSectionContainer
         title={TITLE}
         description={DESCRIPTIONS}
         extras={(
            <SecurityCards />
         )}
         dark
         descriptionProps={{
            className: 'max-w-7xl'
         }}
         className='banner_spacing_lg'
         compact={false}
      />
   )
}