import { SectionContainer, Link, ProSectionContainer, ProCard, MediaInfoCard, GradientBanner, CardOrientationContainer, HilightPoints, CommunitySectionHybrid } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  Link,
  ProSectionContainer,
  ProCard,
  MediaInfoCard,
  GradientBanner,
  CardOrientationContainer,
  HilightPoints,
  CommunitySectionHybrid,
  React
};