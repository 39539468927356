import { cva } from 'class-variance-authority';
import React, { HTMLProps, cloneElement } from 'react';

import { FeaturedIcon } from '../../common/UI/featuredIcon';
import { cn } from '../../../utils/common';


interface Props extends HTMLProps<HTMLDivElement> {
   tab: Record<string, any>
   isActive: boolean
};

// root-variants
const rootVariants = cva(
   cn(
      'relative flex flex-col justify-center overflow-hidden h-full border-[1px] border-solid rounded-md'
   ),
   {
      variants: {
         isActive: {
            true: 'border-primary-200 shadow-primary-200/40',
            false: 'border-transparent bg-gray-200'
         }
      }
   }
);

const cardContentVariants = cva(
   cn(
      "group relative cursor-pointer overflow-hidden  p-4 xl:p-6 ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1  sm:mx-auto  sm:rounded-lg sm:px-10 h-full text-left"
   )
)

export default (prop: Props) => {

   const { className, tab, isActive, ...rest } = prop;

   return (
      <div className={rootVariants({ className, isActive })} {...rest}>
         <div className={cardContentVariants()}>
            <div>
               <div className='w-10 h-10 flex justify-center items-center badge-gradient-primary-start badge-gradient-primary-end badge-gradient-primary-anim p-2  rounded-sm drop-shadow-sm'>
                  {cloneElement(tab?.icon, { className: 'h-5 w-5 m-auto' })}
               </div>
            </div>
            <div className="space-y-4 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 text-left">
               <h5 className="text-gray-900 text-xl font-medium mb-2">{tab.title}</h5>
               <p className='text-justify'>
                  {tab?.description}
               </p>
            </div>
         </div>
      </div>
   )
}