import React from 'react';

import { LottieOptions, useLottie } from 'lottie-react';
import '../../styles/pricing.css';

import { HeroBanner } from '../../components/homepage/herobanner';
import HelpSection from '../../components/homepage/HelpSection';
import CommunitySection from '../../components/homepage/CommunitySection';
import Layout from '../../components/common/Layout';
import { IntegrationPoints } from '../../components/homepage/IntegrationPoints';

// why choose product
import WhyChoose from '../../components/homepage/why-choose-unz'

// WebhookSupports
import WebhookSupports from './webhook-supports';

// observability
import Observability from './observability'

// hero banner image
import heroImage from './unizo_hero_banner.json';

const nodes = [{
   title: 'SaaS ',
   description: 'Effortlessly integrate with Unizo’s cloud-based platform—designed for customers who prioritize simplicity, scalability, and speed.',
   extra: [
      { name: 'Learn More →', to: 'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/cloud.svg',
      alt: 'SaaS'
   }
},
{
   title: 'Self-Managed ',
   description: 'Take full control with Unizo’s self-managed solution. Deploy integrations on your infrastructure to for a tailored solution to your security, compliance, and customization needs.',
   extra: [
      { name: 'Learn More →', to: 'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/deployment-model.svg'
   }
},
{
   title: 'Integration Services',
   description: 'Unizo’s expert services bridge resource and skill gaps, helping you accelerate integration rollouts and fast-track your time to market—all while letting your team focus on core innovations.',
   extra: [
      { name: 'Learn More →', to: '/services' }
   ],
   image: {
      src: '/icon-set/integrations.svg'
   }
}
]

const Home = () => {
   return (
      <Layout disablePageTitleSuffix title='SaaS Integration Platform for Cybersecurity and DevOps - Unizo' hrefLang='en-US' href='https://www.unizo.io' description="Simplify SaaS integrations with Unizo—unified APIs for cybersecurity, DevOps, and compliance SaaS. Build once, integrate smarter. Contact us today!" keywords='Unizo, Integration platform, Saas integration platform, Api integration platforms, Cloud based integration platform, Cybersecurity integrations,Saas integrations,Saas to saas integration, Integrating cloud services, Devops saas platform, Embedded integrations, Embedded integration platform,Embedded saas integrations,Scm integrations, Itsm integrations, Ticketing integrations, Siem integration, Devops integrations, Compliance integrations, Customer facing integrations,Unified api, Unified apis, Open source integration, Open source unified api, Workato,Incident unified api' canonical='https://www.unizo.io'>
         <HeroBanner
            extra={(
               <ImagePanel />
            )}
         />

         <WhyChoose />

         <WebhookSupports />

         <Observability />

         <IntegrationPoints
            nodes={nodes}
            rootClassName='!my-0'
            title='End-to-End Integration Solutions for Modern SaaS'
         />
         <div className="z-0 sticky">
            <HelpSection />
         </div>
         <CommunitySection className='pt-64' />
      </Layout>
   )
};

const lottieOptions: LottieOptions<any> = {
   animationData: heroImage,
   loop: true
}

const ImagePanel = () => {

   const { View } = useLottie(lottieOptions);

   return (
      <div className='mt-10 layout' >
         {/* <img alt="portal_main_img" className={imageVariants()} src="/images/homepage/hero/home-hero-image-3.svg" /> */}
         {View}
      </div >
   )
}

export default Home;
