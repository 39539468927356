import axios from 'axios';

export const createProspects = async (body: { [key: string]: any }) => {

   const headers = {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json"
   }

   return await axios('https://api.unizo.io/www/v1/prospects', { method: 'post', data: body, headers })
}