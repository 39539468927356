import React from 'react';
import { SectionContainer } from '../../components/common/sectionContainer';

const BLOCK_1_TITLE = 'Trace, Audit, and Optimize with Powerful Observability';
const BLOCK_1_TITLE_DESCRIPTIONS = `Unizo’s observability engine provides continuous monitoring for all API interactions, with tools to audit, log, search, and trace calls. This ensures system reliability, simplifies troubleshooting, and offers unmatched transparency and control over integrations for a seamless user experience.
`;


const BLOCK_2_TITLE = 'Event driven Real-Time Connectivity via Webhooks';
const BLOCK_2_TITLE_DESCRIPTIONS = `Unizo's bi-directional APIs use webhooks to enable real-time, two-way communication, ensuring instant data sync, dynamic updates, and seamless integration workflows for responsive customer experiences.
`;


const TITLE = 'Trace, Audit, and Optimize with Powerful Observability';
const DESCRIPTIONS = `Unizo's powerful observability engine ensures continuous visibility and monitoring for all API interactions. It empowers customers with the tools to audit, log, search, and trace API calls to and from the Unizo platform. This comprehensive approach to observability not only enhances system reliability but also simplifies troubleshooting, enabling swift identification and resolution of issues. With Unizo, you gain unparalleled transparency and control over your integrations, ensuring a seamless experience for your users.`
export default () => {
   return (
      <div className=' banner_spacing_md flex flex-col gap-20 layout pb-32'>
         <div className='grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-10 items-center'>
            <SectionContainer
               title={BLOCK_1_TITLE}
               description={BLOCK_1_TITLE_DESCRIPTIONS}
               align='start'
               rootClassName='!p-0'
            />
            <img src='/images/homepage/unz_portal-log-section.png' alt={BLOCK_1_TITLE} className='border border-solid border-primary/20  shadow-xl md:mt-5 max-w-full' />
         </div>

         {/* block 2 */}
         <div className='flex flex-col-reverse lg:grid gap-5 md:grid-cols-2 lg:gap-10 items-center'>
            <img
               src='/images/homepage/webhook_sync.svg'
               alt={BLOCK_2_TITLE}
               className='md:mt-5 max-w-[70%] md:max-w-[90%]'
            />
            <SectionContainer
               title={BLOCK_2_TITLE}
               description={BLOCK_2_TITLE_DESCRIPTIONS}
               align='start'
               rootClassName='!p-0'
            />
         </div>
      </div>
   )
}