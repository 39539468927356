import React, { HTMLProps } from 'react';
import { SectionContainer } from './sectionContainer';
import { cva } from 'class-variance-authority';
import { cn } from '../../utils/common';

type FancyContainerProps = {
   title?: any
   description?: string
   isHero?: boolean
}

const rootCls = cva(
   cn('fancy_gradient_1 min-h-[60vh] flex flex-col justify-center')
)

export const FancyContainer = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & FancyContainerProps>((props, ref) => {

   const { className, title, description, isHero = false, ...rest } = props;

   return (
      <div className={rootCls({ className })} {...rest} ref={ref}>
         <SectionContainer
            title={title}
            description={description}
            titleProps={{ className: '!text-[3rem] lg:!text-[4rem]' }}
            isHero={isHero}
            descriptionProps={{ className: 'text-2xl text-gray-800 font-medium' }}
         />
      </div>
   )
})