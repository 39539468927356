import React, { cloneElement } from 'react';
import { GraphUp, Gear, ShieldCheck, BarChartLine } from '@styled-icons/bootstrap'
import { MoneyWithdraw } from '@styled-icons/boxicons-regular';
import { ArrowTrendingSettings,Handshake } from '@styled-icons/fluentui-system-regular';
import './table.css';

const features = [
   {
      title: 'Accelerate Your Roadmap',
      description: 'Focus on building your core product while Unizo handles the heavy lifting of integrations. Our unified API Platform is tailor made for cybersecurity, compliance and DevOps SaaS that streamlines your development process, saving time and resources.​',
      icon: <GraphUp />, // Replace with actual icon or icon component if you have one
   },
   {
      title: 'Exclusive Startup Support',
      description: 'Gain direct access to Unizo’s team for hands-on help with integration setup, troubleshooting, and optimization. Collaborate with our product team to provide feedback and influence the evolution of Unizo’s platform.​',
      icon: <ArrowTrendingSettings />, // Replace with actual icon
   },
   {
      title: 'Deeply Discounted Pricing',
      description: 'Start for free with zero upfront costs, making it easier for early-stage startups to experiment and validate their use cases. Enjoy substantial discounts as you scale, helping you stay lean without compromising on quality or functionality.',
      icon: <MoneyWithdraw />, // Replace with actual icon
   },
   {
      title: 'First Access to New Features',
      description: `Participate in beta programs and be the first to leverage cutting-edge functionalities that align with your business goals.​`,
      icon: <BarChartLine />, // Replace with actual icon
      // className: 'col-start-2 col-span-1'
   },
   {
      title: 'Request and co-develop new Integrations',
      description: ` Request custom integrations tailored to your unique needs. Collaborate with Unizo’s development team to bring these integrations to life, ensuring they fit seamlessly into your workflows.​`,
      icon: <Handshake />, // Replace with actual icon
      // className: 'col-start-2 col-span-1'
   }
];
const tableData = [
   { feature: 'Free Tier', benefit: 'Get started with Unizo without any financial commitment.' },
   { feature: 'Tailored Support', benefit: 'Dedicated guidance from our integration experts.' },
   { feature: 'Product Collaboration', benefit: 'Influence the direction of Unizo’s platform to better meet your needs.' },
   { feature: 'Discounted Pricing', benefit: 'Access premium capabilities at a fraction of the cost.' },
   { feature: 'Early Feature Access', benefit: 'Stay ahead of the competition with early access to innovations.' },
   { feature: 'Custom Integrations', benefit: 'Collaborate with Unizo to develop integrations that fit your needs.' },
];

export const BenefitsTable = ({ data }: { data: Array<{ feature: string; benefit: string }> }) => {
   return (
      <div>
         <div className="py-16 px-6 md:px-20 lg:px-32 layout">
            <h3 className="text-2xl font-bold mb-6 sph3">Program Benefits at a Glance</h3>
            <table className="w-full text-left border-separate" style={{ borderSpacing: '0 10px' }}>
               <thead>
                  <tr>
                     <th className="px-6 py-3 text-sm font-semibold text-gray-600">Feature</th>
                     <th className="px-6 py-3 text-sm font-semibold text-gray-600">Benefit</th>
                  </tr>
               </thead>
               <tbody>
                  {tableData.map((row, index) => (
                     <tr key={index} className="bg-white">
                        <td className="px-6 py-4 text-gray-700">{row.feature}</td>
                        <td className="px-6 py-4 text-gray-600">{row.benefit}</td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
};


export const TalkingStrings = () => {
   return (
      <div className='services-talking-points'>
         <div className=" py-16 px-6 md:px-20 lg:px-32 layout">
            <h2 className="container-title ">
               Why Join Unizo for Startups?
            </h2>
            <div className="services-talking-points-list gap-5 justify-items-center" >
               {features.map(({ className, ...feature }: Record<string, any>, index) => (
                  <div key={index} className={`bg-white p-6 text-center  ` + className} style={{ maxWidth: '18rem' }}>
                     <div className='flex flex-row justify-center'>
                        <div className='featuredIcon mb-4 flex-shrink-0'>
                           {feature.icon ? cloneElement(feature.icon, {
                              className: "h-6 w-6",
                           }) : null}
                        </div>
                     </div>
                     <h3 className="text-xl text-gray-800 mb-3">
                        {feature.title}
                     </h3>
                     <p className="text-gray-600 font-normal">{feature.description}</p>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};


