import React, { HTMLProps, useMemo } from 'react';
import { cva } from 'class-variance-authority';
import { Divider } from 'antd';

import { Link } from 'gatsby';
import { cn } from '../utils/common';

type TileTypes = {
   "name": string,
   "isPopular": boolean,
   description: string,
   "featuredIcon": {},
   "pricing": {
      "message": string,
      "i18n": {},
      "currency": string,
      "price": number
   },
   services: { name: string }[]
}

type TilesProps = {
   dataSource?: TileTypes[]
}

type TileProps = {
   item?: TileTypes
}

const tilesClassName = cva(
   cn('flex flex-row justify-center w-full flex-wrap gap-2.5')
)

const tileClassName = cva(
   cn(
      'card max-w-[270px] h-full border-[.25px]  border-solid  shadow-xl rounded-xl  ring-primary-200/20 shadow-primary-200/10'
   ),
   {
      variants: {
         isPopular: {
            true: 'border-[.25px] border-primary-200',
            false: ''
         }
      }
   }
);

const listCls = cva(
   cn('list-none text-md flex flex-row items-center gap-2.5 text-gray-800')
)

const Tile = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & TileProps>(
   (props, ref) => {

      const { item, className, ...rest } = props;

      if (!item) return

      const {
         name,
         description = '',
         services,
         pricing,
         isPopular
      } = item;

      const currencyExtract = useMemo(() => {

         if (!pricing?.message) return null;

         const pattern = /[\$\€\£\¥\₹\₩\₽\฿\₫\₪\₴\₦]/;

         if (!pattern.test(pricing.message)) return pricing.message;
         const msgArr = pricing.message.match(pattern);

         if (!msgArr?.length) return pricing?.message;

         const [cur] = msgArr

         let { message } = pricing;

         message = message.replace(cur, '');

         return (
            <span className='flex flex-row items-start'>
               <span className='text-gray-700 text-[.8rem] mt-[9.5px]' >{cur}</span>
               <span className='text-[1.4rem]' >{message}</span>
               {message !== 'custom' ? (
                  <span className='text-gray-700 mt-[6px]'>/mo</span>
               ): null}
            </span>
         )

      }, [pricing?.message])

      return (
         <div className='  shadow-sm relative scale-[.8] xl:scale-[.9] 2xl:scale-[1]' style={{ alignSelf: 'normal' }}>
            {
               isPopular && (
                  <>
                     <div className='styles-module--focusBg--afe08'></div>
                     <div className="styles-module--focusBorder--85e45"></div>
                  </>
               )
            }
            <div
               className={tileClassName({ className, isPopular })}
               style={{ alignSelf: 'normal' }}
               {...rest}
               ref={ref}
            >
               <div className=' flex flex-col items-start justify-start gap-4'>
                  <div className='flex flex-col gap-4 items-start justify-start'>
                     <div className={`flex gap-4 flex-row items-center justify-start ${name !== "Enterprise - Self Managed" ? 'mb-8' : ''}`}>
                        <span className='text-2xl font-bold' >{name}</span>
                     </div>
                     <span className='text-md' >{description}</span>
                  </div>

                  <span
                     className={`text-[1rem] font-bold ${currencyExtract === "Custom" ? 'mb-2.5' : ''}`}
                  >
                     {currencyExtract}
                  </span>

                  <Link to='/lets-chat' role='button' className={cn('btn !text-md btn-lg ', `${isPopular ? 'btn-primary' : 'btn-outline'}`)}>
                     Get started →
                  </Link>

                  <Divider />
                  <span className='text-lg font-medium'>Includes:</span>
                  <div
                     className='flex flex-col gap-4 flex-1'
                  >
                     {services.map(({ name }, key) => {
                        return (
                           <li
                              className={listCls()}
                              key={key}
                           >
                              <Checked />
                              {name}
                           </li>
                        )
                     })}
                  </div>
               </div>
            </div>
         </div>
      )
   })

const Tiles = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & TilesProps>(
   (props, ref) => {

      const { dataSource, className, ...rest } = props;

      return (
         <div className={tilesClassName({ className })} {...rest} ref={ref}>
            {dataSource?.map((item, index) => {
               return (
                  <Tile
                     item={item}
                     key={index}
                  />
               )
            })}
         </div>
      )
   })

function Checked() {
   return (
      <svg className="table__tick flex-shrink-0" width="4mm" height="4mm" viewBox="0 0 14 14">
         <circle cx="7" cy="7" r="7" style={{ fill: 'rgb(66 66 66 / var(--tw-text-opacity))' }}></circle>
         <path d="M3.23,6.46,6.46,9.69l4.31-5.38" style={{ fill: 'none', stroke: '#fff', strokeWidth: '1.5px' }} ></path>
      </svg>
   )
}

export default Tiles
