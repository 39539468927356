import * as React from "react";
import {
    Drawer,
    IconButton,
    DrawerProps,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { useLocation } from '@reach/router';


// Styles
import "../../styles/app.css";
import { Link } from "gatsby";
import { Header } from "./header";
import { Brand } from "./Brand";
import { routes, common } from '../../utils/index';
import { AppContext, AppProvider } from "../../context/appContext";
import links from '../../../docs/links';
import { ListItemComp } from "./megaMenu";
import Footer from '../../components/homepage/HomeFooter';
import { ChevronDown } from '@styled-icons/boxicons-regular';
import { Helmet } from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { cva } from "class-variance-authority";
import { cn } from "../../utils/common";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

type MenuDrawerProps = Omit<DrawerProps, 'placeholder' | 'onPointerEnterCapture' | 'onPointerLeaveCapture'>;
const appLink = 'https://app.unizo.io';
const { clsMerge } = common;
const mockProps = {
    children: undefined,
    placeholder: undefined,
    onPointerEnterCapture: undefined,
    onPointerLeaveCapture: undefined
}

type MenuTypes = { [key: string]: any }
type MenuDrawerTypes = MenuDrawerProps;
type CollapsedMenuTypes = {
    onSelect?: (selected: MenuTypes | null) => void
    selected: string
    onClose?: () => void
};
type DefaultLayoutProps = {
    children?: React.ReactNode
    hideHeader?: boolean;
    title?: string
    disablePageTitleSuffix?: boolean
    mainCls?: string
    hrefLang?: string;
    href?: string;
    description?: string;
    keywords?: string;
    canonical?: string
}

const menuVariant = cva(
    cn("site-main overflow-x-hidden")
)

const CollapsedMenu = React.forwardRef((props: CollapsedMenuTypes, ref: React.LegacyRef<any>) => {
    const {
        selected: selectedProp = '/',
        onSelect,
        onClose
    } = props;
    const [opened, setOpened] = React.useState<number | false>(false);
    const { links: { headers: items } } = React.useContext(AppContext);

    const handleOpen = (index: number, hasChild: boolean) => {
        if (hasChild) {
            setOpened(index === opened ? false : index)
        } else {
            if (typeof onSelect === 'function') {
                onSelect(items.length ? items[index] : null)
            }
        }
    }

    const clsMerger = clsMerge(
        'w-full p-1  hover:bg-gray-200 cursor-pointer font-medium'
    )

    return (
        <div ref={ref}>
            {items.map((item: any, index) => {
                const isOpened = opened === index;
                const selected = routes.isRouteMatch(selectedProp, item?.value ?? '');

                const hasChild = item?.children && item?.children?.length > 0;
                const { label, target = 'self' } = item;

                return (
                    <Accordion key={index.toString()} {...mockProps} open={isOpened}>
                        {hasChild ? (
                            <AccordionHeader
                                {...mockProps}
                                onClick={() => handleOpen(index, hasChild)}
                                className={`p-1 hover:bg-gray-200 cursor-pointer w-full`}
                            >
                                <span className={`text-sm font-[inherit] text-gray-700`}>
                                    {label}
                                </span>
                                <ChevronDown className={`${isOpened ? 'rotate-180 -mt-[0px]' : ''} text-gray-700 h-6 w-6 ml-auto -mt-[2px]`} />
                            </AccordionHeader>
                        ) : (

                            <Link
                                to={item?.to ?? '/'}
                                className="w-full !text-gray-700"
                                target={target}
                                onClick={() => {
                                    onClose && onClose();
                                }}
                            >
                                <div
                                    className={
                                        clsMerger({ className: selected ? 'text-primary-200' : 'text-gray-700' })
                                    }
                                >
                                    <span className="text-sm font-[inherit] ">
                                        {label}
                                    </span>
                                </div>
                            </Link>
                        )}
                        <AccordionBody>
                            {item?.children && item?.children.map(
                                ({ label, to, desc, icon }, key) => (
                                    <Link to={to} key={key}>
                                        <ListItemComp label={label} desc={desc} />
                                    </Link>
                                ),
                            )}
                        </AccordionBody>
                    </Accordion>
                )
            })}
        </div>
    )
})

const CloseIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    )
}

const MenuDrawer = React.forwardRef(
    (props: MenuDrawerTypes, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
        const { open, onClose, ...rest } = props;
        const { pathname } = useLocation();

        return (
            <Drawer
                {...mockProps}
                open={open}
                onClose={onClose}
                {...rest}
                className="p-4"
                size={410}
                ref={ref}
            >
                <div className="mb-6 flex items-center justify-between">
                    <Brand className="-mt-2" />
                    <IconButton
                        {...mockProps}
                        variant="text"
                        color="blue-gray"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <CollapsedMenu onClose={onClose} selected={pathname} />
                <Link
                    to={appLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary my-2"
                >
                    Log In
                </Link>
            </Drawer>
        )
    })

const appName = 'Unizo';

const DefaultLayout = (props: DefaultLayoutProps) => {
    const { children,
        disablePageTitleSuffix = false,
        hideHeader = false,
        title,
        mainCls = '',
        hrefLang,
        href,
        description,
        keywords,
        canonical,
    } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const [closed, setClosed] = React.useState<boolean>(false);

    const { pathname } = useLocation();

    const onOpen = () => {
        setOpen(true);
    };

    const onToggleClose = () => {
        setClosed(true);
    }

    const withPrifix = React.useMemo(() => {

        if (disablePageTitleSuffix) return title;

        return `${title} - ${appName}`;

    }, [title, disablePageTitleSuffix])

    const pageTitle = title ? withPrifix : React.useMemo(() => {
        if (!pathname) return '';

        return pathname.replace(/^\/|\/$/g, '').replace(/\//g, ' | ')
    }, [pathname]);

    React.useEffect(() => {
        AOS.init({
            duration: 500,
            once: true
        });
    }, []);

    return (
        <AppProvider value={{ links } as any}>
            <Helmet>
                <title>{pageTitle}</title>
                <script id="cookieyes" type="text/javascript"
                    src="https://cdn-cookieyes.com/client_data/0920974cb12d193fc8f06942/script.js"
                ></script>
                {hrefLang && href && (
                    <link rel="alternate" hrefLang={hrefLang} href={href} />
                )}
                <meta
                    name="title"
                    content={'Unizo'}
                />
                {
                    description &&
                    <meta
                        name="description"
                        content={description}
                    />
                }
                {
                    keywords &&
                    <meta
                        name="keywords"
                        content={keywords}
                    />
                }
                {/* Open Graph tags for social media */}
                <meta
                    property="og:title"
                    content="Unizo - Empowering SaaS Vendors with Fast Integrations"
                />
                <meta
                    property="og:description"
                    content="Launch customer-facing integrations 10x faster with Unizo. Focus on building exceptional products for business growth."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://unizo.io" />
                <meta
                    property="og:image"
                    content="https://unizo.io/images/unz_dark_logo.svg"
                />
                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="Unizo - Empowering SaaS Vendors with Fast Integrations"
                />
                <meta
                    name="twitter:description"
                    content="Launch customer-facing integrations 10x faster with Unizo. Focus on building exceptional products for business growth."
                />
                <meta
                    name="twitter:image"
                    content="https://unizo.io/images/unz_dark_logo.svg"
                />
                <meta name="google-site-verification" content="vt7VRNaKv7QPMyZX2UXxST97b95sOGcLyLoT40h-ndo" />
                <meta name="robots" content="index, follow"></meta>
                <link rel="canonical" href={canonical} />
                <script src="//embed.typeform.com/next/embed.js" />
                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Unizo",
                        "alternateName": "Unizo Inc.",
                        "url": "https://www.unizo.io/",
                        "logo": "https://www.unizo.io/",
                        "sameAs": [
                            "https://x.com/Unizoio",
                            "https://www.youtube.com/@unizo-io",
                            "https://www.linkedin.com/company/unizoio/",
                            "https://github.com/unizo-io"
                        ]
                        }
                    `}
                </script>

                <link rel="stylesheet" href="//embed.typeform.com/next/css/popup.css" />
            </Helmet>


            {!closed && !hideHeader && (
                <div>
                    <div
                        className="w-full bg-gradient-to-r primary-gradient p-4 text-center font-medium text-white flex justify-end items-center"
                    >
                        <span className="ml-auto">
                            🚀 Grow and scale your startups with Unizo. Talk to us for startup specials!{' '}
                            <Link
                                to="/unizo-for-startups"
                                className="text-white underline"
                            >
                                Learn more →
                            </Link>
                        </span>
                        <button style={{ backgroundColor: 'transparent', border: 'none' }} title="close" className="ml-auto cursor-pointer" onClick={onToggleClose}  ><CloseIcon /></button>
                    </div>
                </div>
            )}

            {!hideHeader && <Header onOpen={onOpen} />}
            <main className={menuVariant({ className: mainCls })}>
                {children}
                <Footer />
            </main>

            <MenuDrawer
                onClose={() => setOpen(false)}
                children={null}
                open={open}
            />

        </AppProvider >
    );
};

export default DefaultLayout;
export type { MenuTypes }
