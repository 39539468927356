import React, { HTMLProps, cloneElement } from 'react';
import { Link } from 'gatsby';
import { cn } from '../../utils/common';
import { SectionContainer } from '../common/sectionContainer';

const nodesLocal = [

]

type NodeTypes = {
   title?: string,
   image?: HTMLProps<HTMLImageElement>,
   description?: any,
   extra?: { name: string, to: string, target?: string }[]
}

type IntegrationPointsProps = {
   nodes?: NodeTypes[]
   rootClassName?: string
   description?: any
}

export const IntegrationPoints = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & IntegrationPointsProps>(
   ({ nodes: nodeProp, title, rootClassName, description, ...props }, ref) => {

      const nodes = nodeProp ?? nodesLocal;

      return (
         <div className={cn('banner_spacing_lg bg-[var(--ifm-color-secondary-lighter)]', rootClassName)} {...props} ref={ref}>
            <div className='flex flex-col items-center justify-center gap-[4rem]'>

               <SectionContainer rootClassName='!py-0' title={title} description={description} />
               <div className='grid gap-5 2xl:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 layout -mt-[1.1rem]'>
                  {nodes.map((node, index) => {
                     const { title, description, extra, image } = node;
                     return (
                        <div key={index} className='card flex flex-col gap-2 p-8'>
                           <div className='flex flex-col gap-2'>
                              {image?.src && (
                                 <img className='h-[4.375rem] w-[4.375rem]' src={image.src} alt={image?.alt || node?.title} />
                              )}
                              <h2 className='font-semibold mt-2'>{title}</h2>
                              <p className='text-justify'>{description}</p>
                           </div>
                           {extra && extra.map(({ to = '/', name }, i) => {
                              return (
                                 <Link target='' to={to} key={i} className='flex  w-fit flex-row items-center justify-start gap-2'>
                                    {name && cloneElement(<span>{name}</span>, { className: 'hover:underline' })}
                                 </Link>
                              )
                           })}
                        </div>
                     )
                  })}
               </div>
            </div>
         </div>
      )
   })