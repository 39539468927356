// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-details-index-tsx": () => import("./../../../src/pages/careers-details/index.tsx" /* webpackChunkName: "component---src-pages-careers-details-index-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-integrations-index-tsx": () => import("./../../../src/pages/integrations/index.tsx" /* webpackChunkName: "component---src-pages-integrations-index-tsx" */),
  "component---src-pages-lets-chat-index-tsx": () => import("./../../../src/pages/lets-chat/index.tsx" /* webpackChunkName: "component---src-pages-lets-chat-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-templates-careers-detail-js": () => import("./../../../src/templates/careers-detail.js" /* webpackChunkName: "component---src-templates-careers-detail-js" */),
  "component---src-templates-freqask-js": () => import("./../../../src/templates/freqask.js" /* webpackChunkName: "component---src-templates-freqask-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

