import React, { HTMLProps, cloneElement, useMemo, useState } from 'react';
import { Form, Row, Col, message } from 'antd';

import { PlatformSectionPros, ProSectionContainer } from '../ProSectionContainer';
import { cn } from '../../../utils/common';
import { cva } from 'class-variance-authority';
import useCaptcha from '../../../hooks/useCaptcha';
import { createProspects } from '../../../service/letsChat';

type FormFieldTypes = {
   name: string
   label: string
   placeholder: string
   errorMessage?: string
   required?: boolean
   span?: number
   type?: string
}

type CTATypes = {
   formFields: FormFieldTypes[]
   formTitle?: string
   actions?: {
      method: 'get' | 'post' | 'put' | 'patch',
      url: string
      headers: { [key: string]: string }
   }
   additionalFormValues: { [key: string]: any },
   onFailed?: (err: any) => void
   onSuccess?: (resp: any) => void
   submitBtnProps?: HTMLButtonElement
} & PlatformSectionPros;

const defaultFields = [
   { name: 'firstName', placeholder: 'First Name', label: 'First Name', span: 12, type: 'text', required: true },
   { name: 'lastName', placeholder: 'Last Name', label: 'Last Name', span: 12, type: 'text', required: true },
   { name: 'company', placeholder: 'Company', label: 'Company', type: 'text', required: true },
   { name: 'email', placeholder: 'Work Email', label: 'Work Email', type: 'email', required: true },
   { name: 'size', placeholder: 'Organization size', label: 'Company size (estimated number of employees)', type: 'number', required: true },
]

const rootClassName = cva(
   cn('py-16 overflow-x-hidden'),
   {
      variants: {
         dark: {
            true: 'primary-gradient',
            false: ''
         },
         bg: {
            'gray': ''
         }
      },
      compoundVariants: [
         {
            dark: false,
            bg: 'gray',
            className: 'bg-[var(--ifm-color-secondary-lighter)]'
         },
         {
            dark: true,
            bg: 'gray',
            className: 'primary-gradient'
         }
      ]
   }
)

const formTitleClassName = cva(
   cn('text-xl font-semibold text-gray-800 text-center mb-7')
)

const ThankYouPage = () => {
   return (
      <div className="flex flex-col gap-5 items-center justify-center ">
         <div className="bg-green-500 justify-center flex items-center flex-shrink-0 text-white rounded-full p-2 w-[49px] h-[49px]">
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth="2"
               stroke="currentColor"
               className="w-6 h-6"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
               />
            </svg>
         </div>
         <h1 className="text-xl font-medium text-white text-center leading-8">Thanks for submitting the form. We'll be in touch with you ASAP.</h1>
      </div>
   );
}

export const CTA = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & CTATypes>(
   (props, ref) => {

      const {
         formFields = [],
         title,
         description,
         eyebrow,
         className,
         formTitle = 'Connect with us',
         actions,
         submitBtnProps = {},
         dark = true,
         onFailed,
         onSuccess,
         additionalFormValues = {},
         ...rest
      } = props;

      const { isVerified, element: captchaElement } = useCaptcha();
      const [form] = Form.useForm();
      const [messageApi, contextHolder] = message.useMessage()
      const [loading, setLoading] = useState<boolean>(false);

      const [submitted, setSubmitted] = useState<Boolean>(false);

      const mergeFields = useMemo(() => {
         return defaultFields.concat(formFields as any)
      }, [formFields]);

      let content: string;
      let code: number;
      let type: 'error' | 'success' = 'success';

      const onSubmit = async () => {

         try {
            const formDetails = await form.validateFields();

            const payload = {
               type: 'DEMO_REQUEST',
               name: formDetails.company,
               description: formDetails.description,
               size: formDetails.size ? +formDetails.size : 0,
               industry: formDetails.industry,
               contact: {
                  firstName: formDetails.firstName,
                  lastName: formDetails.lastName,
                  designation: formDetails.designation,
                  emails: [
                     {
                        type: 'WORK',
                        email: formDetails.email
                     }
                  ]
               },
               ...additionalFormValues
            };

            try {
               setLoading(true);

               await createProspects(payload);
               content = 'Requested successfully.';
               form.resetFields();
               setSubmitted(true);

            } catch (error) {
               const errorData = error?.response?.data;
               code = error?.response?.status || 500;

               if (Array.isArray(content) && content.length > 0) {
                  content = content[0].errorMessage;
               } else {
                  content = errorData?.error ?? 'Something went wrong!'
               }
               type = 'error';
            } finally {
               messageApi.open({
                  type,
                  content: `${code ? code : ''} ${content}`,
               });
               setTimeout(setLoading, 500, false);

            }
         } catch (err) {
            console.log(err);
         }
      };

      return (
         <div className={rootClassName({ className, dark, bg: 'gray' })}  {...rest} ref={ref}>
            {contextHolder}
            <div
               data-aos="zoom-in"
               className='grid grid-cols-1 lg:grid-cols-2 items-center gap-10 layout'
            >
               <ProSectionContainer
                  title={title}
                  description={description}
                  eyebrow={eyebrow}
                  align='start'
                  sectionContainerClassName='!py-0'
                  className='!py-0'
                  dark={dark}
                  data-aos="fade-left"
               />
               {!submitted ? (
                  <Form form={form} data-aos="fade-right" className='card p-10 lg:max-w-[500px] lg:ml-auto'>
                     {formTitle && <span className={formTitleClassName()} >{formTitle}</span>}
                     <Row gutter={[25, 0]}>
                        {Array.isArray(mergeFields) && mergeFields.map((formItem, index) => {

                           const {
                              name,
                              label,
                              type = 'text',
                              span = 24,
                              required = false
                           }: any = formItem;

                           let rules = {};

                           if (type === 'email') {
                              rules = { ...rules, ['type']: 'email' }
                           }

                           return (
                              <Col span={span}>
                                 <Form.Item name={name} key={index} rules={[{ required, ...rules }]} >
                                    <div className="form-item">
                                       <label htmlFor={name}>{label}</label>
                                       <input min={1} type={type} id={name} required={required} className='input w-full'  />
                                    </div>
                                 </Form.Item>
                              </Col>
                           )
                        })}
                     </Row>

                     {captchaElement && (
                        cloneElement(captchaElement)
                     )}
                     <button
                        disabled={!isVerified}
                        className='btn btn-lg btn-primary mt-5'
                        onClick={onSubmit}
                        {...submitBtnProps}
                     >
                        {loading ? 'Submitting...' : 'Submit'}
                     </button>
                     <p className='text-gray-500 leading-1 mt-5 text-xs'>
                        By submitting your information, you agree to be contacted by a Unizo representative.
                     </p>
                  </Form>
               ) : (
                  <ThankYouPage />
               )}

            </div>
         </div>
      )
   })