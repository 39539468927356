import React, { ReactNode, useContext, useMemo } from 'react';
import {
   NavigationMenu,
   NavigationMenuContent,
   NavigationMenuItem,
   NavigationMenuLink,
   NavigationMenuList,
   NavigationMenuTrigger,
   navigationMenuTriggerStyle,
} from "../NavigationMenu/index";
import { cn } from '../../../utils/common';
import { Link } from 'gatsby';
import { AppContext } from '../../../context/appContext';

import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { ChevronDown } from '@styled-icons/boxicons-regular';

const CLASS_NAME_S = {
   0: {
      className: 'w-[553px]'
   },
   1: {
      className: 'w-[553px]'
   },
   2: {
      className: ''
   },
   3: {
      className: ' w-[553px]'
   },
   4: {
      className: ''
   },
   5: {
      className: ''
   },
   6: {
      className: ' w-[553px]'
   }
}

export default () => {

   const { links: { headers: links } } = useContext(AppContext);

   const navLinks = useMemo(() => {
      return (
         links.map((link, index) => {

            let titleLabel: ReactNode

            if (!link?.children?.length) {
               titleLabel = (
                  <Link
                     to={link?.to}
                  >
                     <NavigationMenuPrimitive.Link className={navigationMenuTriggerStyle()}>
                        <div className="text-sm font-medium leading-none text-accent-foreground">{link?.label}</div>
                     </NavigationMenuPrimitive.Link>
                  </Link>
               )
            } else {
               titleLabel = (
                  <NavigationMenuPrimitive.Trigger className={navigationMenuTriggerStyle()}>
                     <div className='text-sm font-medium leading-none text-accent-foreground'>{link?.label}</div>
                     
                     <ChevronDown
                        className="relative top-[1px] ml-1 h-5 w-5 transition duration-200 group-data-[state=open]:rotate-180"
                        aria-hidden="true"
                     />
                  </NavigationMenuPrimitive.Trigger>
               );
            }

            return (
               <NavigationMenuPrimitive.Item key={index} value={index?.toString()}>
                  {titleLabel}
                  <NavigationMenuPrimitive.Content
                     className='absolute p-5  left-0 top-0 w-full data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft sm:w-auto'
                  >
                     <ul
                        className={cn("grid gap-2 grid-cols-2 !mb-0", CLASS_NAME_S[index]?.className)}
                     >
                        {link.children?.map((child: any, childIndex) => {
                           return (
                              <ListItem to={child?.to ?? '/'} title={child?.label} key={childIndex} {...child} />
                           )
                        })}
                     </ul>
                  </NavigationMenuPrimitive.Content>

               </NavigationMenuPrimitive.Item>
            )
         })
      )
   }, [links])

   return (
      <NavigationMenuPrimitive.Root className='relative z-10 flex max-w-max flex-1 items-center justify-center' >
         <NavigationMenuPrimitive.List className='group flex flex-1 list-none items-center justify-center space-x-1 mb-0'>
            {navLinks}
            <NavigationMenuPrimitive.Indicator className="top-full z-10 flex h-2.5 items-end justify-center overflow-hidden transition-[width,transform_250ms_ease] data-[state=hidden]:animate-fadeOut data-[state=visible]:animate-fadeIn">
               <div className="relative top-[70%] size-2.5 rotate-45 rounded-tl-sm bg-white" />
            </NavigationMenuPrimitive.Indicator>
         </NavigationMenuPrimitive.List>
         <div className="perspective-[2000px] absolute left-0 top-full flex w-full justify-center">
            <NavigationMenuPrimitive.Viewport className="relative mt-2.5 h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-lg shadow-lg border-[1px] border-solid border-gray-300 bg-white transition-[width,_height] duration-300 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn sm:w-[var(--radix-navigation-menu-viewport-width)]" />
         </div>
      </NavigationMenuPrimitive.Root>
   )
}

const ListItem = React.forwardRef<any, any>(({ className, title, children, ...props }, ref) => {
   return (
      <li>
         <NavigationMenuPrimitive.Link asChild>
            <LinkTitle className={className} title={title} {...props} ref={ref} />
         </NavigationMenuPrimitive.Link>
      </li>
   )
});


const LinkTitle = React.forwardRef<any, any>(({ className, title, icon, image, extra, ...props }, ref) => {
   return (
      <Link
         ref={ref}
         className={cn(
            "block select-none space-y-1  rounded-sm p-2.5 leading-none no-underline outline-none transition-colors text-accent-foreground hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
         )}
         {...props}
      >
         <div className='flex justify-between items-center'>
            <div className='flex flex-row gap-2 items-center justify-start truncate'>
               <div>
                  {""}
                  {icon ? React.createElement(icon as any, {
                     className: "h-5 w-5 text-primary-200",
                  }) : (
                     image?.src ? (
                        <img className={cn("h-5 w-5", image?.className)} alt={title} {...image} />
                     ) : null
                  )}
               </div>
               <div className="text-sm font-medium leading-none truncate">{title}</div>
            </div>
            {extra ? (
               <span className="rounded-md text-[9px] font-semibold p-[5px] bg-primary-100/5 text-primary-200 text-nowrap">
                  {extra}
               </span>
            ) : null}
         </div>
      </Link>
   )
})

ListItem.displayName = "ListItem"