import React, { HTMLProps, ReactElement, cloneElement, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../../../../utils/common";

type Props = {
   activeIndex: number,
   slides: Array<{ [key: string]: any }>
} & HTMLProps<HTMLDivElement>;

const MOTION_DIV_STYLE_S: React.CSSProperties = {
   width: "100%",
   height: '100%'
}

const slideVariants: { [key: string]: Record<string, any> } = {
   enter: (direction: number) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
   }),
   center: { x: 0, opacity: 1 },
   exit: (direction: number) => ({
      x: direction > 0 ? -300 : 300,
      opacity: 0,
      display: 'none'
   }),
};

const Carousel = (props: Props) => {

   const { activeIndex, slides = [], className } = props;

   return (
      <div
         className={cn('overflow-hidden relative', className)}
      >
         <AnimatePresence custom={activeIndex}>
            <motion.div
               key={slides[activeIndex].id}
               custom={activeIndex}
               variants={slideVariants}
               initial="enter"
               animate="center"
               exit="exit"
               transition={{ duration: 0.5 }}
               style={MOTION_DIV_STYLE_S}
               onMouseEnter={() => {
                  console.log('enter')
               }}
               onMouseLeave={() => {
                  console.log('leave')
               }}
            >
               {slides[activeIndex].content}
            </motion.div>
         </AnimatePresence>
      </div>

   );
};

export default Carousel;
