import React, { CSSProperties } from 'react';
import { ProSectionContainer } from '../../components/common/ProSectionContainer';

const TITLE = 'Enterprise Grade Protection';
const DESCRIPTIONS = `Our security framework is designed to protect your integrations, emphasizing governance, risk management, and compliance at every step`

const CARD_STYLE: CSSProperties = {
   background: 'radial-gradient(circle at 50% 0px, rgb(33, 43, 61), rgb(28, 34, 49))'
}

const CARD_ITEM_S: { title: string, description: string }[] = [
      {
        "title": "Secure Infrastructure",
        "description": "Infrastructure in ISO 27001, SOC 2, and PCI Level 1-certified facilities, with firewalls securing external and internal system access."
      },
      {
        "title": "No Data Caching",
        "description": "Zero-liability security posture by transmitting customer data directly to your application without storing or caching it on Unizo servers."
      },
      {
        "title": "Bring Your Own Logging (BYOL)",
        "description": "With Unizo, your API logs remain yours. Ship them to your observability tools (e.g., Datadog) for secure monitoring and full control, or use Unizo’s logging service."
      },
      {
        "title": "Data Encryption",
        "description": "Data security at rest with 256-bit AES encryption; SSL/TLS secure tunnel between your app and our API for data transfer."
      },
      {
        "title": "Secure SDLC",
        "description": "Industry-standard secure coding guidelines with regular scanning of source code and applications for vulnerabilities."
      },
      {
        "title": "Bring Your Own Key (BYOK)",
        "description": "Enhance protection by storing customer API credentials (OAuth 2 tokens, API keys) in your KMS."
      },
      {
        "title": "Realtime Monitoring",
        "description": "A secure system with behavioral monitoring, vulnerability assessments, SIEM, and intrusion detection for identification and mitigation of threats."
      },
      {
        "title": "Multi-Factor Authentication",
        "description": "Two-factor authentication and strong password controls for administrative access to all systems."
      }
];

const SecurityCards = () => {
   return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-6 mt-2">

         {CARD_ITEM_S.map((item, index) => {
            return (
               <div style={CARD_STYLE} className="p-6 rounded-lg shadow-lg text-start bg-gradient-to-r from-blue-gray-800 via-blue-gray-800 to-blue-gray-800" key={index} >
                  <h3 className="text-xl font-bold">{item.title}</h3>
                  <p className='!mb-0 mt-2 font-normal leading-6 text-sm text-white break-words text-justify max-w-full'>
                     {item.description}
                  </p>
               </div>
            )
         })}

      </div>
   );
};

export default () => {
   return (
      <ProSectionContainer
         title={TITLE}
         description={DESCRIPTIONS}
         extras={(
            <SecurityCards />
         )}
         dark
         descriptionProps={{
            className: 'max-w-7xl'
         }}
         className='banner_spacing_lg'
         compact={false}
      />
   )
}