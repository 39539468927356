import React, { useMemo } from 'react';

import posts from '../../../docs/partials/careers/posts.json';
import Layout from '../../components/common/Layout';

const MAIL_TO: string = 'careers@unizo.io';

const STATIC_MESSAGE = `Alternatively, you can email your resume along with a brief statement about why you're excited to join Unizo to <b>${MAIL_TO}.</b Applications without both the resume and statement will not be considered.`

export default function Details(props) {

   const { id } = props.pageContext;

   const fullBody = useMemo(() => (
      posts?.find((i) => i.id === id) ?? null
   ), [id]);

   const title = fullBody?.title ?? null,
      description = fullBody?.company?.description ?? null,
      qualification = fullBody?.qualifications ?? null,
      responsibilities = fullBody?.responsibilities ?? null,
      compensation = fullBody?.compensation ?? null,
      why = fullBody?.whyUnizo ?? null,
      titleMain=fullBody?.titleHeader,
      canonicallink=fullBody?.canonical
      

   const onApply = () => {
      const subject = `Applying for position - ${title}`;
      (window as any).location = `mailto:${MAIL_TO}?subject=${subject}`
   }

   return (
      <Layout title={title as string} hrefLang='en' href={`https://www.unizo.io/careers/detail/${id ?? ""}`} canonical={canonicallink}>
         <div className='layout py-[4rem] lg:py-[8rem]'>
            <div className=" pb-6">
               <div className="mx-auto max-w-6xl">
                  {/* Job Title */}
                  <div className='flex flex-col gap-3 lg:flex-row lg:gap-5'>
                     <h1 className="text-3xl md:text-5xl font-bold text-gray-800 !leading-[2.8rem] md:!leading-[3.8rem]" >
                        {titleMain}
                     </h1>
                     {/* Apply Now Button */}
                     <div className='flex-shrink-0'>
                        <button
                           className={(
                              "mt-5 px-4 py-2 border-2 border-solid bg-primary-200 border-primary-200 text-white font-medium rounded-lg hover:bg-primary-200/80 hover:text-white transition hover:border-primary-200/80 "
                           )}
                           onClick={onApply}
                        >
                           Apply Now
                        </button>
                     </div>
                  </div>

                  {/* Tags */}
                  <div className="flex justify-start gap-3 mt-6">
                     {fullBody?.tags?.map((tag, index) => {
                        return (
                           <div key={index} className='careers-badge careers-badge-selected'>
                              <span className="badge-dot mr-2"></span>
                              {tag}
                           </div>
                        )
                     })}
                  </div>

                  {/* Description */}
                  <p
                     className="text-md mt-6 leading-relaxed"
                     dangerouslySetInnerHTML={{ __html: description as string }}
                  />

               </div>
            </div>

            <Description container={qualification} />
            <Description container={responsibilities} />

            {/* compensation */}
            {compensation ? (
               <div className='max-w-6xl mx-auto py-12 pb-6'>
                  <h3 className="text-3xl font-bold text-primary-200 mb-3">
                     {compensation?.label}
                  </h3>
                  <div className="space-y-4 text-lg text-gray-800 mt-6">
                     <span className='text-md' >{compensation?.summary}</span>
                  </div>
               </div>
            ) : null}

            <div className='max-w-6xl mx-auto py-12 pb-6'>
               <h3 className="text-3xl font-bold text-primary-200 mb-3">
                  {why?.label}
               </h3>
               <div className="space-y-4 text-lg text-gray-800 mt-6">
                  {why?.details?.map((detail, index) => (
                     (
                        <div key={index} className='flex flex-col ml-5'>
                           <div className='flex flex-row items-center'>
                              <span className='badge-dot mr-4'></span>
                              <span className='text-md' >{detail}</span>
                           </div>
                        </div>
                     )
                  ))}
               </div>
            </div>

            <div className='max-w-6xl mx-auto py-12 pb-6 mt-4'>
               <span className='text-lg text-gray-800 !leading-7' dangerouslySetInnerHTML={{ __html: STATIC_MESSAGE }} />
            </div>
         </div>
      </Layout>
   )
}

export const Description = ({ container }) => {
   return (
      <div className='max-w-6xl mx-auto py-12 pb-6'>
         <h3 className="text-3xl font-bold text-primary-200 mb-3">
            {container?.label}
         </h3>

         <div className="space-y-8 text-lg text-gray-800 mt-4">
            {container?.details?.map(({ details, label }, parentIndex) => (
               (
                  <div key={parentIndex} className='flex flex-col space-y-4'>
                     <span className='text-lg font-semibold text-gray-800'>{label}</span>
                     {details.map((detail, index) => {
                        return (
                           <div key={index} className='ml-5'>
                              <div className='flex flex-row items-center'>
                                 <span className='badge-dot mr-4'></span>
                                 <span className='text-md' >{detail}</span>
                              </div>
                           </div>
                        )
                     })}
                  </div>
               )
            ))}
         </div>
      </div>
   )
}