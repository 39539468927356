import React, { HTMLProps } from 'react';
import { Divider } from 'antd';

import { Layout } from '../../components/common';
import { SectionContainer } from '../../components/common/sectionContainer';
import pricing from './pricing.json';
import { Link } from 'gatsby';
import CommunitySection from '../../components/homepage/CommunitySection'
import Tiles from '../../components/Tiles';
import { cva } from 'class-variance-authority';
import { cn } from '../../utils/common';
import { Faq } from '../../components/FAQ';
import { SectionContainerH2 } from '../../components/common/sectionContainerh2';


type PricingAPISProps = {
   APIS?: {
      label: string,
      description: string,
      imageSrc?: string
      icon?: React.ReactElement
   }[]
};

const rootPricingAPISCls = cva(
   cn('layout'),
   {
      variants: {}
   }
)

const integrationTypes = [
   {
      name: 'Source Code',
      description: 'Integrate with industry leading SCM providers.',
      image: {
         src: '/icon-set/code.svg',
         alt: 'source code'
      },
      to: '/integrations/scm'
   },
   {
      name: 'Packages and Container Registries',
      description: 'Out of the box package management.',
      image: {
         src: '/icon-set/container.svg',
         alt: 'container registry'
      },
      to: '/integrations/pcr'
   },
   {
      name: 'Ticketing',
      description: 'Ready made Ticketing integration and ITSM providers.',
      image: {
         src: '/icon-set/unz_Ticketing.svg',
         alt: 'ticketing'
      },
      to: '/integrations/ticketing'
   },
   {
      name: 'Communications',
      description: 'Leverage pre-built chat tools integrations.',
      image: {
         src: '/icon-set/comms.svg',
         alt: 'communication'
      },
      to: '/integrations/communications'
   },
   {
      name: 'Incident Management',
      description: 'Leverage pre-built chat tools integrations.',
      image: {
         src: '/icon-set/comms.svg',
         alt: 'communication'
      },
      to: '/integrations/Incident-Management'
   }
];

const cardCls = cva(
   cn(
      'group relative overflow-hidden bg-white  ring-1 ring-gray-900/5   sm:rounded-lg shadow-sm',
      'min-w-[250px]'
   ),
   {
      variants: {

      }
   }
)


const PricingAPIS = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & PricingAPISProps>(
   (props, ref) => {

      const {
         className,
         ...rest
      } = props;

      return (
         <div
            ref={ref}
            className={rootPricingAPISCls({ className })}
            {...rest}
         >
            <SectionContainerH2
               title={'Available APIs for Subscription'}
               titleProps={{ as: 'h4' }}
               rootClassName='!py-0'
               noAnim
            />
               <div className='my-10 flex flex-row items-center gap-5 justify-center lg:justify-start flex-wrap lg:ml-[66px]'>
                  {integrationTypes.map((item, key) => (
                     <div
                        key={key}
                        className={cardCls({})}
                     >
                        <div className="relative z-10 py-2 px-2 mx-auto max-w-md flex flex-row gap-2.5 items-center justify-start">
                           <img {...item?.image} className="w-10 h-10" />
                           <div className="text-base font-semibold ">
                              <label className="text-md font-bold tracking-wide text-black block ">{item?.name}</label>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
         </div>
      )
   })


const Pricing = () => {

   return (
      <Layout title='Pricing' hrefLang='en' href='https://www.unizo.io/pricing' canonical='https://www.unizo.io/pricing'>
         <SectionContainer
            title={'Flexible pricing for every team'}
            description="Choose a pricing plan that fits your team's needs. Unizo offers flexible, scalable options so you can integrate efficiently, whether you're a startup or an enterprise scaling operations."
            descriptionProps={{ className: '!text-lg' }}
            rootClassName='!py-20 pricing_banner'
         />
         <div
            className='py-10 layout '
         >
            <PricingAPIS />
         </div>
         <div className='h-[1px] shade-divider w-[50%] layout bg-gray-200'>

         </div>
         <div id='pricing_catalog' className='layout'>
            <Tiles dataSource={pricing} className='xl:my-10 xl:mt-20' />
         </div>

         {/* <Faq filterBy={['Pricing']} /> */}

         <CommunitySection title={<p><span className='text-primary-200'>Not sure which plan is right for you?</span></p>}
            description={<span style={{ display: 'inline-flex', alignItems: 'center'}}> <Link
               to="/lets-chat"
               className="font-medium hover:underline flex items-center text-lg"
            >
              Talk to our experts
            </Link> &nbsp; and find the perfect fit for your needs!</span>}
            actions={[{ label: 'Schedule a Call' }]} />
      </Layout>
   )
};


export default Pricing;