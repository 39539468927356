import React, { cloneElement, useMemo } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../utils/common';
import { WithFadeUp } from '../../hoc/withFadeup';
import { AspectRatio } from './UI/aspectratio';

type AttributesTypes = {
   className?: string
   style?: React.CSSProperties
   as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'sx' | 'sm' | 'md' | 'lg' | 'xl' | '2xl',
   strong?: 'bold' | 'semi-bold' | 'medium' | 'regular' | 'light';
}

type ImageType = {
   url: string
   alt?: string
   border?: boolean
   className?: string
   width?: number | string
   height?: number | string
};

export type SectionContainerProps = {
   title?: any
   description?: string
   titleProps?: AttributesTypes
   descriptionProps?: AttributesTypes
   extras?: React.ReactElement[] | React.ReactElement
   /**
   * @deprecated The method should not be used
   */
   imgUrl?: string
   image?: ImageType
   eyebrow?: React.ReactNode
   className?: string
   rootClassName?: string

   align?: 'start' | 'end' | 'center'
   centerBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
   isHero?: boolean
   noAnim?: boolean

   // compact space
   compact?: boolean
};

const titleCls = cva('text-black', {
   variants: {
      as: {
         'h1': 'text-[3rem] leading-[normal] md:text-[4rem] md:leading-[5rem]',
         'h2': 'h2',
         'h3': 'text-[2.5rem] leading-[3.4rem] ',
         'h4': 'text-[2rem] ',
         'h5': 'text-[1.5rem]',
         'h6': 'text-[1rem]',
         'xs': 'text-xs',
         'sm': 'text-sm',
         'md': 'text-md',
         'lg': 'text-lg',
         'xl': 'text-xl',
         '2xl': 'text-2xl'
      },
      strong: {
         'bold': 'font-bold',
         'semi-bold': 'font-semibold',
         'medium': 'font-medium',
         'regular': 'font-normal',
         'light': 'font-light'
      },
   },
   defaultVariants: {
      as: 'h2',
      strong: 'bold'
   }
});

const descCls = cva(
   'max-w-4xl  !mb-0',
   {
      variants: {
         as: {
            'xs': 'text-xs leading-5',
            'sm': 'text-sm leading-7',
            'md': 'text-md leading-7',
            'lg': 'text-lg leading-8',
            'xl': 'text-xl leading-8',
            '2xl': 'text-2xl leading-10'
         },
         strong: {
            'bold': 'font-bold',
            'semi-bold': 'font-semibold',
            'medium': 'font-medium',
            'regular': 'font-normal',
            'light': 'font-light'
         },
         isHero: {
            true: 'text-gray-700',
            false: ''
         }
      },
      defaultVariants: {
         as: 'md',
         strong: 'regular'
      }
   }
)

const innerCls = cva(
   "flex w-full reveal ml-auto flex-col gap-3  justify-between",
   {
      variants: {
         align: {
            start: 'items-start text-left',
            center: 'items-center text-center',
            end: 'items-end text-right'
         },
         centerBreakPoint: {
            'sm': '',
            'md': '',
            'lg': '',
            'xl': '',
            'xxl': ''
         },
         compact: {
            true: 'compact',
            false: ''
         }
      },
      defaultVariants: {
         centerBreakPoint: 'md'
      },
      compoundVariants: [

      ]
   }
)

const imgCls = cva(
   cn(
      "max-w-[100%]",
   ),
   {
      variants: {
         border: {
            true: 'border border-solid border-gray-300',
            false: ''
         }
      }
   }
)

const rootCls = cva(
   cn("relative  layout noise-bg no-underline-links"),
   {
      variants: {
         isHero: {
            true: 'banner_spacing_lg',
            false: 'banner_spacing_md'
         }
      }
   }
)

export const SectionContainer = (props: SectionContainerProps) => {
   const {
      title,
      description,
      titleProps,
      descriptionProps,
      extras = [],
      imgUrl = '',
      image,
      eyebrow,
      className,
      rootClassName,
      align = 'center',
      isHero = false,
      noAnim = false,

      // compact space
      compact = true,
   } = props;

   const classNames = useMemo(() => {
      return {
         desc: descriptionProps?.className,
         title: titleProps?.className
      }
   }, [descriptionProps, titleProps]);

   const imgMemo: any = useMemo(() => {
      if (!image) return null;
      const { alt, url, className, border = false, ...rest } = image;

      return (
         <div
            className='flex layout items-center mt-20 justify-center '
         >
            <img
               alt={alt}
               className={imgCls({ className, border })}
               src={url}
               {...rest}
            />
         </div>
      )
   }, [image])

   const descriptionClsVariants = useMemo(() => {
      let stdVar = { bold: 'regular', as: 'md' };

      return { ...stdVar, ...descriptionProps }

   }, [descriptionProps]);

   const heroStyleOverWriterDescription = useMemo(() => {

      if (!isHero) return {
         as: descriptionClsVariants?.as ?? 'md',
         string: descriptionClsVariants?.strong ?? 'regular'
      }

      return { as: 'lg', strong: 'regular' }
   }, [isHero, descriptionClsVariants])

   let animProps = {};

   if (!noAnim) {
      animProps = {
         ...animProps,
      }
   }

   return (
      <section
         className={
            rootCls({
               className: rootClassName,
               isHero
            })
         }
      >
         <div {...animProps} className={innerCls({ className, align, compact })}>
            {eyebrow && (
               <p className='text-primary-200 mb-[0] uppercase'>{eyebrow}</p>
            )}
            {title && (
               !isHero ? (
                  <h1
                     className={
                        titleCls({
                           className: classNames?.title,
                           as: titleProps?.as ?? 'h2',
                           strong: titleProps?.strong ?? 'normal'
                        } as any)
                     }
                  >
                     {title}
                  </h1>
               ) : (
                  <h1
                     className={
                        titleCls({
                           className: classNames?.title,
                           as: 'h1',
                           strong: "semi-bold"
                        } as any)
                     }
                  >
                     {title}
                  </h1>
               )
            )}
            {description && (
               typeof description === 'string' ? (
                  <p
                     className={descCls({
                        className: classNames?.desc,
                        isHero,
                        ...heroStyleOverWriterDescription
                     } as any)}
                  >
                     {description}
                  </p>
               ) : cloneElement(description,
                  {
                     className: descCls({
                        className: classNames?.desc,
                        ...heroStyleOverWriterDescription
                     } as any),
                  })
            )}
            {extras && Array.isArray(extras) ? (
               <div className='flex flex-col md:flex-row gap-3 items-center justify-center'>
                  {extras.map((extra, key) => (
                     cloneElement(extra, { key })
                  ))}
               </div>
            ) : (
               extras ? (
                  <WithFadeUp>
                     {extras}
                  </WithFadeUp>
               ) : null
            )}
         </div>
         {imgUrl && (
            <img alt="portal_img" data-aos="zoom-in" className={imgCls({ border: false })} src={imgUrl} />
         )}
         {imgMemo && (<WithFadeUp>{imgMemo}</WithFadeUp>)}
      </section>
   )
}
