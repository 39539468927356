import { Link } from "gatsby";
import React from "react";

import { ArrowUpRight } from '@styled-icons/bootstrap'
import FlipWords from "../../common/UI/flip-words";

const HeroBadge = () => {
   return null;

}

export const Title = ({ children = null }: { children: any }) => {
   return (
      <h1 className='section_title text-center !text-[3rem] lg:!text-[4rem]'>
         {children}
      </h1>
   )
}

type HeroBannerTypes = {
   title?: string
   title2?: string
   description?: string
   minimal?: boolean
   extra?: any
}
const words: string[] = ["Cybersecurity SaaS", "DevOps SaaS", "Compliance SaaS"];

const DefaultTitle = () => {
   return (
      <div className="relative">Turnkey Integrations for
         <FlipWords className="primary-text-gradient text-center" words={words} />
      </div>
   )
}

export const HeroBanner = (props: HeroBannerTypes) => {

   const {
      title = <DefaultTitle />,
      title2 = null,
      description = `Say goodbye to scattered, one-off integration efforts. Unizo provides a seamless, efficient platform to standardize your integration workflows. Tailored for the unique needs of cybersecurity and DevOps SaaS providers, our solution helps you satisfy customer needs, cut down on technical complexities, and excel in today’s connected digital landscape.`,
      minimal,
      extra = null
   } = props;

   return (
      <div className="bg-gradient-background-1">
         <div className={(
            "relative  bg-gradient-background-anim bg-[length:400%_400%] h-full"
         )}>
            <div className="bg-gradient-background-shade w-full bg-gradient-shade h-full">
               <div className='banner_spacing_md flex-col flex h-full'>
                  <section className="relative  layout noise-bg pb-5 no-underline-links px-4 lg:py-0">
                     <div className="flex compact w-full ml-auto flex-col gap-5 items-center justify-between py-14">
                        <HeroBadge />
                        {title && (
                           <Title >{title} </Title>
                        )}
                        {title2 && (
                           <Title>{title2}</Title>
                        )}
                        {description && (
                           <p className="text-xl mb-0 text-gray-700 font-small text-center">
                              {description}
                           </p>
                        )}
                        {
                           !minimal && (
                              <div className='flex items-center flex-col gap-2 mt-5'>
                                 <Link to="/lets-chat" className="relative btn-anim px-8 lg:px-16 py-3 bg-gray-900 text-white hover:text-white rounded-full hover:bg-gray-800 focus:outline-none">
                                    Schedule a Demo
                                 </Link>
                              </div>
                           )
                        }

                     </div>
                  </section>
                  {
                     extra && extra
                  }
               </div>
            </div>
         </div>
      </div>
   )
}
