import React, { HTMLProps, cloneElement } from 'react';

import { cva } from 'class-variance-authority';
import { SectionContainer, SectionContainerProps } from './sectionContainer';
import { ProCard, ProCardProps } from './ProCard';
import { cn } from '../../utils/common';
import { WithFadeUp } from '../../hoc/withFadeup';

type CardListTypes = {
   imgUrl?: string
   name?: string
};

type NodeTypes = 'json' | 'html';

export type PlatformSectionPros = {
   curved?: boolean
   dark?: boolean
   eyebrow?: React.ReactNode

   title?: any
   description?: string

   descriptionProps?: HTMLProps<HTMLParagraphElement>
   actions?: React.ReactNode | React.ReactNode[]

   cardList?: CardListTypes[]

   extraNodes?: NodeTypes extends 'json' ? Array<ProCardProps> : React.ReactElement
   nodeType?: NodeTypes
   nodeRootClassName?: string
   sectionContainerClassName?: string
   cardTransparent?: boolean

   isHero?: boolean

   noAnim?: boolean

   bg?: 'gray' | 'white'
   nodeLayout?: 'grid' | 'flexbox'

   itemClassName?: string

} & Pick<SectionContainerProps, 'align' | 'extras' | 'titleProps' | 'compact'>

const rootCls = cva(
   '',
   {
      variants: {
         dark: {
            true: '',
            false: ''
         },
         bg: {
            'gray': '',
            'white': '',
         },
         isHero: {
            true: 'banner_spacing_lg',
            false: 'banner_spacing_md'
         }
      },
      compoundVariants: [
         {
            dark: false,
            bg: 'gray',
            className: 'bg-[var(--ifm-color-secondary-lighter)]'
         },
         {
            dark: false,
            bg: 'white',
            className: 'bg-white'
         },
         {
            dark: true,
            bg: ['gray', 'white'],
            className: 'primary-gradient text-white'
         }
      ]
   }
)

const curveCls = cva(
   '',
   {
      variants: {
         dark: {
            true: 'primary-gradient ',
            false: ''
         },
         placement: {
            top: 'mb-[-50px]',
            bottom: 'mt-[-50px]'
         }
      }
   }
)

const descCls = cva(
   '',
   {
      variants: {
         dark: {
            true: '!text-white',
            false: ''
         }
      }
   }
)

const titleCls = cva(
   '',
   {
      variants: {
         dark: {
            true: '!text-white',
            false: '!text-black'
         }
      }
   }
)

const providerCardCls = cva(
   cn('min-h-[100px] min-w-[200px] flex flex-row items-center justify-center rounded-lg flex-shrink-0'),
)

const extraNodesWrapper = cva(
   'flex mt-5 flex-row flex-wrap gap-10 w-full justify-center overflow-hidden items-center layout',
   {
      variants: {
         dark: {
            true: 'text-white',
            false: ''
         },
         nodeLayout: {
            flexbox: 'flex',
            grid: 'grid'
         }
      }
   }
)

const ProviderCard = React.forwardRef<HTMLDivElement,
   HTMLProps<HTMLDivElement> & { url?: string }
>((props, ref) => {
   const { className, url, title, ...rest } = props;
   return (
      <div
         className={providerCardCls({ className })}
         style={{ transition: '.4s all' }}
         {...rest}
         ref={ref}
         title={title}
      >
         <img
            style={{ objectFit: 'contain', imageResolution: 'crisp-edges' }}
            src={url}
            alt={title}
            className='aspect-[16/9] max-w-[200px] max-h-[200px]'
         />
      </div>
   )
})

export const ProSectionContainer = React.forwardRef<HTMLDivElement,
   HTMLProps<HTMLDivElement> & PlatformSectionPros
>((props, ref) => {
   const {
      curved = false,
      dark = false,
      eyebrow,
      className,
      title,
      description,
      bg = 'white',
      cardTransparent = false,
      cardList = [],
      extraNodes,
      nodeRootClassName,
      nodeType = 'json',
      align = 'center',
      nodeLayout = 'flexbox',
      sectionContainerClassName,
      extras,
      titleProps,
      descriptionProps,
      isHero = false,
      noAnim = false,
      itemClassName = '',
      compact = true,
      ...rest
   } = props;

   const validJson = nodeType === 'json' && Array.isArray(extraNodes) && extraNodes.length;

   return (
      <>
         {curved && <Curve />}
         <div className={rootCls({ className, dark, bg, isHero })} {...rest} ref={ref} >
            <SectionContainer
               title={title}
               eyebrow={eyebrow}
               description={description}
               noAnim={noAnim}
               className='!p-0 !pt-0'
               rootClassName={cn(sectionContainerClassName, '!pt-0 !pb-0')}
               titleProps={{
                  ...titleProps,
                  className: titleCls({ className: titleProps?.className, dark })
               }}
               align={align}
               isHero={isHero}
               extras={extras}
               descriptionProps={{
                  ...descriptionProps,
                  className: descCls({ className: descriptionProps?.className, dark })
               } as any}
               compact={compact}
            />

            {cardList.length ? (
               <div
                  data-aos={noAnim ? null : "zoom-in"}
                  className='px-5 mt-5 xl:px-0 flex flex-wrap flex-row justify-center items-center xl:gap-[7rem] layout'
                  style={{ gridRowGap: '3rem', alignContent: 'center' }}
               >
                  {cardList.map((item: any, index) => {
                     return (
                        <ProviderCard
                           className={'min-w-[300px] ' + itemClassName}
                           title={item?.name}
                           key={index}
                           url={item?.imgUrl}
                        />
                     )
                  })}
               </div>
            ) : null}

            {validJson ? (
               <div
                  data-aos={noAnim ? null : "zoom-in"}
                  className={extraNodesWrapper({
                     dark,
                     nodeLayout,
                     className: nodeRootClassName
                  })}
               >
                  {extraNodes.map((items, index) => {

                     const {

                        points = [],
                        title,
                        badge,
                        link,
                        description,
                        icon,
                        image,
                        className,
                        pointsProps,

                     } = items;

                     return <ProCard
                        className={
                           cn(
                              'min-w-full max-w-full ',
                              nodeLayout == 'flexbox' ? 'lg:max-w-[400px] lg:min-w-[400px]' : '',
                              className,
                              itemClassName
                           )
                        }
                        pointsProps={pointsProps}
                        transparent={cardTransparent}
                        key={index}
                        badge={badge}
                        icon={icon}
                        points={points}
                        title={title}
                        image={image}
                        link={link}
                        description={description}
                     />
                  })}
               </div>
            ) : extraNodes && <WithFadeUp>
               <div
                  className={'overflow-hidden mt-5'}
               >
                  {extraNodes}
               </div>
            </WithFadeUp>}
         </div>
         {curved && <Curve className='rotate-180' placement='bottom' />}
      </>
   )
})

const Curve = ({ dark, className, placement = 'top' }: { [key: string]: any }) => {
   return (
      <div className={curveCls({ dark, className, placement })}>
         <svg
            id="wave"
            style={{ transform: "rotate(0deg)", transition: "0.3s" }}
            viewBox="0 0 1440 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
         >
            <defs>
               <linearGradient id="sw-gradient-0" x1={0} x2={0} y1={1} y2={0}>
                  <stop stopColor="rgba(26, 46, 68, 1)" offset="0%" />
                  <stop stopColor="rgba(26, 46, 68, 1)" offset="100%" />
               </linearGradient>
            </defs>
            <path
               style={{ transform: "translate(0, 0px)", opacity: 1 }}
               fill="url(#sw-gradient-0)"
               d="M0,0L240,11.7C480,23,960,47,1440,46.7C1920,47,2400,23,2880,16.7C3360,10,3840,20,4320,33.3C4800,47,5280,63,5760,73.3C6240,83,6720,87,7200,73.3C7680,60,8160,30,8640,23.3C9120,17,9600,33,10080,41.7C10560,50,11040,50,11520,41.7C12000,33,12480,17,12960,20C13440,23,13920,47,14400,53.3C14880,60,15360,50,15840,38.3C16320,27,16800,13,17280,8.3C17760,3,18240,7,18720,13.3C19200,20,19680,30,20160,38.3C20640,47,21120,53,21600,51.7C22080,50,22560,40,23040,33.3C23520,27,24000,23,24480,18.3C24960,13,25440,7,25920,13.3C26400,20,26880,40,27360,51.7C27840,63,28320,67,28800,66.7C29280,67,29760,63,30240,60C30720,57,31200,53,31680,53.3C32160,53,32640,57,33120,48.3C33600,40,34080,20,34320,10L34560,0L34560,100L34320,100C34080,100,33600,100,33120,100C32640,100,32160,100,31680,100C31200,100,30720,100,30240,100C29760,100,29280,100,28800,100C28320,100,27840,100,27360,100C26880,100,26400,100,25920,100C25440,100,24960,100,24480,100C24000,100,23520,100,23040,100C22560,100,22080,100,21600,100C21120,100,20640,100,20160,100C19680,100,19200,100,18720,100C18240,100,17760,100,17280,100C16800,100,16320,100,15840,100C15360,100,14880,100,14400,100C13920,100,13440,100,12960,100C12480,100,12000,100,11520,100C11040,100,10560,100,10080,100C9600,100,9120,100,8640,100C8160,100,7680,100,7200,100C6720,100,6240,100,5760,100C5280,100,4800,100,4320,100C3840,100,3360,100,2880,100C2400,100,1920,100,1440,100C960,100,480,100,240,100L0,100Z"
            />
         </svg>

      </div>
   )
}
