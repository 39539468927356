import React from "react";

const salesPoint = [
    {
        label: 'Launch integrations in weeks, not quarters.'
    },
    {
        label: 'Avoid delays and capture more sales.'
    },
    {
        label: 'Enter new markets smoothly. '
    },
    {
        label: 'Save on building, maintaining, and upgrading integrations. '
    },
    {
        label: 'Prioritize your product roadmap. '
    },
    {
        label: 'Ensure seamless integration with standardized diagnostics.'
    },
    {
        label: 'Prevent errors and data corruption. '
    },
    {
        label: 'Protect systems from cascading integration failures. '
    }
]
const SectionSideText = ({ className }) => {
    return (
        <div className={className}>
            <div>
                <h1 className="mb-[0] font-jakarta banner-titleLetsChart font-bold">
                    We’d love to chat.
                </h1>
                <p className="max-w-2xl mt-4 mb-4">
                    Unizo empowers enterprise SaaS providers with solutions that streamline integrations,
                    accelerate time-to-market, and drive business growth.
                </p>
                <div className="letsChartTicksTextHead">
                    {salesPoint.map(({ label }) => {
                        return (
                            <div className="letsChartTickstext">
                                <img width={30} height={30} src="/images/letsChatTick.svg" alt={label} />
                                <p className="text-black mb-0">
                                    {label}
                                </p>
                            </div>
                        )
                    })}
                    <p className="font-light text-sm">
                        *All calls with Unizo team are currently available in english only.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SectionSideText
