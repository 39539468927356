import React from "react";
import { Link } from 'gatsby';


const LetschatHeader = () => {
    return (
        <div className=" w-[100%] h-14 sticky top-0 z-[20] bg-white p-2 pl-2 xl:pl-5 mb-8">
            <div className="layout" style={{marginLeft:"1.4rem"}}>
                <Link to='/' >
                    <img src="/images/unz_dark_logo.svg" alt="lets chat with Unizo" width={100} height={28} />
                </Link>

            </div>
        </div>

    )
}

export default LetschatHeader;